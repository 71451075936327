import React, { useMemo, useEffect, useState } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Login from "./components/login";
import PrivateRoute from "./components/PrivateRoute";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  lighten,
} from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Added routing imports
import "./components/page.css";

export default function App() {
  const [data, setData] = useState([]); // Initially set to an empty array
  const [open, setOpen] = useState(false); // State to control the modal open/close
  const [selectedRow, setSelectedRow] = useState(null); // State to hold the selected row data
  const [valueBeingUsed, setValueBeingUsed] = useState(0); // State to hold the input value
  const [pnr, setPnr] = useState(""); // State to hold the PNR input value

  // Fetch Data Effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://kmma-gv-api-fqfae5a3bwfnb5a0.westeurope-01.azurewebsites.net/api/orders",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "1aa094ff-1f80-4b37-b1f6-7db57209ef6a",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Define columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.OrderNo}`,
        header: "Order No.",
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => (
          <strong>KMVO-{renderedCellValue.padStart(5, "0")}</strong>
        ),
      },
      {
        accessorKey: "Name",
        header: "Sender Name",
      },
      {
        accessorKey: "Surname",
        header: "Sender Surname",
      },
      /*
      {
        accessorFn: (row) => `${row.VoucherCode}`,
        header: "Voucher Code",
        Cell: ({ renderedCellValue }) => (
          <strong>KMGV-{renderedCellValue.padStart(5, "0")}</strong>
        ),
      }, */
      {
        accessorKey: "VoucherCode2",
        header: "Voucher Code",
      },
      {
        accessorKey: "Mobile",
        header: "Sender Mobile",
      },
      {
        accessorKey: "Email",
        header: "Sender Email",
      },
      {
        accessorKey: "RecipientFirstName",
        header: "Recipient First Name",
      },
      {
        accessorKey: "RecipientSurname",
        header: "Recipient Surname",
      },
      {
        accessorKey: "RecipientEmail",
        header: "Recipient Email",
      },
      {
        accessorKey: "VoucherValue",
        header: "Value",
      },
      {
        accessorKey: "RemainingValue",
        header: "Remaining Value",
      },
      {
        accessorKey: "ValidFrom",
        header: "Purchase Date",
      },
      {
        accessorKey: "PaymentStatus",
        header: "Payment Status",
      },
      {
        accessorKey: "PaymentDate",
        header: "Payment Date",
      },
      {
        accessorKey: "RecipientDeliveryDate",
        header: "Delivery Date",
      },
      {
        accessorKey: "RecipientMailSentDate",
        header: "Delivered on",
      }
    ],
    []
  );

  // Material React Table setup
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    enableBatchRowSelection: false,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      pagination: { pageSize: 20 },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30, 50],
    },
    renderTopToolbar: ({ table }) => {
      const handleActivate = () => {
        const selectedRowData = table.getSelectedRowModel().flatRows[0]?.original;
        if (selectedRowData) {
          setSelectedRow(selectedRowData);
          setOpen(true);
        }
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: "flex",
            gap: "0.5rem",
            p: "8px",
            padding: "5px",
            justifyContent: "space-between",
          })}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="success"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleActivate}
                variant="contained"
              >
                Log Voucher Use
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setValueBeingUsed(0);
    setPnr("");
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        "https://kmma-gv-api-fqfae5a3bwfnb5a0.westeurope-01.azurewebsites.net/api/redemption",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "1aa094ff-1f80-4b37-b1f6-7db57209ef6a",
          },
          body: JSON.stringify({
            voucherNo: selectedRow?.VoucherCode,
            orderNo: selectedRow?.OrderNo,
            valueUsed: valueBeingUsed,
            pnr: pnr,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Redemption successful");
      handleClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to redeem voucher");
    }
  };

  return (
    <Router>
      <Routes>
        {/* Public Login Route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Main Application Route */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <div className="App">
                <Header />
                <div className="content">
                  <MaterialReactTable table={table} />
                </div>
                <Footer />
                {selectedRow && (
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                      <strong>Use Voucher</strong>
                    </DialogTitle>
                    <DialogContent>
                      <p>
                        <strong>Voucher No:</strong> KMGV-{selectedRow.VoucherCode}
                      </p>
                      <p>
                        <strong>Order No:</strong> KMVO-{selectedRow.OrderNo}
                      </p>
                      <p>
                        <strong>Value:</strong> {selectedRow.VoucherValue}
                      </p>
                      <p>
                        <strong>Remaining Value:</strong> {selectedRow.RemainingValue}
                      </p>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Value Being Used"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={valueBeingUsed}
                        onChange={(e) => setValueBeingUsed(e.target.value)}
                      />
                      <TextField
                        margin="dense"
                        label="PNR"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={pnr}
                        onChange={(e) => setPnr(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="secondary">
                        Cancel
                      </Button>
                      <Button onClick={handleSubmit} color="primary">
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
